import { memo, useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useTranslation } from 'react-i18next';
import Spacing from '../../../components/Spacing';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import Divider from '../../../components/Divider';
import { Controller, useForm } from 'react-hook-form';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import FindPlusIntegration from '../../../services/findplus_integration/service';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { setAccount, setShowLoginForm } from '../redux/login_slice';
import { Link, useNavigate } from 'react-router-dom';
import { RouterRoutes } from '../../../App';
import AthenaSettings, { AthenaSettingsKeys } from '../../../utils/athena_settings';

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setSubmit] = useState(false);

    const { t } = useTranslation();
    const { control, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            username: '',
            password: '',
            rememberMe: false
        }
    });

    const isFieldError = value => {
        return value === undefined ? false : value !== null && value !== "";
    };

    const errorTextBoxClass = value => {
        let className = 'e-outline';
        if (isFieldError(value)) className += ' e-error'

        return className;
    }

    const errorLabelClass = value => {
        let className = 'field-error-message';
        if (!isFieldError(value)) className += ' hide'

        return className;
    }

    const onSubmit = async payload => {
        try {
            setSubmit(true);
            const isRememberMe = payload.rememberMe;
            delete payload.rememberMe;

            const accountService = FindPlusIntegration.getAccountService();
            const response = await accountService.authenticate(payload);
            if (response.status < 400) {
                const settings = new AthenaSettings();
                settings.setItem(AthenaSettingsKeys.rememberMe, {
                    value: isRememberMe,
                    date: new Date().toISOString()
                });

                const data = response.data;
                dispatch(setAccount(data));
                navigate(RouterRoutes.icpl);
                // if (data.UserIDEx.includes('ICPL')) {

                // }
                // else {
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Authorized Account',
                //     });
                // }
            }
        } catch (error) {
            const response = error?.response;
            Swal.fire({
                icon: 'error',
                title: 'Login Error',
                text: response?.status === 400 ? 'Invalid Username or Password' : `Unknown Error (${response.status})`
            });
        }
        setSubmit(false);
    }

    return (
        <>
            <div className="flex column">
                <Spacing />
                <Controller
                    name="username"
                    control={control}
                    rules={{
                        required: t('login.invalidUsername')
                    }}
                    render={({ field }) => {
                        const errorMessage = errors.username?.message;
                        return (
                            <>
                                <TextBoxComponent
                                    {...field}
                                    cssClass={errorTextBoxClass(errorMessage)}
                                    placeholder={t('login.usernameLabel')}
                                    floatLabelType='Always' />
                                <label className={errorLabelClass(errorMessage)}>{errorMessage}</label>
                            </>
                        );
                    }} />
                <Spacing />
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: t('login.invalidPassword')
                    }}
                    render={({ field }) => {
                        const errorMessage = errors.password?.message;
                        return (
                            <>
                                <TextBoxComponent
                                    {...field}
                                    type='password'
                                    cssClass={errorTextBoxClass(errorMessage)}
                                    placeholder={t('login.passwordLabel')}
                                    floatLabelType='Always' />
                                <label className={errorLabelClass(errorMessage)}>{errorMessage}</label>
                            </>
                        )
                    }} />
                <Spacing />
                <Controller
                    name="rememberMe"
                    control={control}
                    render={({ field }) => (
                        <CheckBoxComponent
                            checked={field.value}
                            label={t('login.rememberMeLabel')} />
                    )} />
                <Spacing />
                <ProgressButtonComponent
                    cssClass="e-primary"
                    content={t('login.loginBtnTitle')}
                    duration={!isSubmitting ? 800 : 100000}
                    spinSettings={{
                        position: 'Center'
                    }}
                    animationSettings={{
                        effect: 'ZoomOut'
                    }}
                    onClick={handleSubmit(onSubmit)}>
                </ProgressButtonComponent>
                <Divider />
                <div className="center-content">
                    <Link className='forgot-password' onClick={() => dispatch(setShowLoginForm(false))}>{t('login.forgotPassword')}</Link>
                </div>
                <Divider />
                <div className="logo-container">
                    <img src="assets/find.png" alt="Powered by Find" />
                </div>
            </div>
        </>
    );
}

export default memo(LoginForm);