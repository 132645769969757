import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { LocalizationLng } from "../../locales/i18n";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { User } from 'react-feather';
import { useSelector } from "react-redux";
import { loginStore } from "../../container/login/redux/login_slice";
import AthenaSettings, { AthenaSettingsKeys } from '../../utils/athena_settings';
import FooterTableActions from './components/TableActions';


export default function Footer() {
    const [isEN, setIsEN] = useState(LocalizationLng.en);

    const { account } = useSelector(loginStore);
    const { t, i18n } = useTranslation();
    const settings = useRef(new AthenaSettings());

    useEffect(() => {
        settings.current.refresh();
        const selectedLang = settings.current.value.language;
        i18n.changeLanguage(selectedLang);
        setIsEN(selectedLang === LocalizationLng.en);
        // eslint-disable-next-line
    }, []);

    const handleChange = args => {
        setIsEN(!args.checked);
        const selectedLang = args.checked ? LocalizationLng.cn : LocalizationLng.en;
        settings.current.setItem(AthenaSettingsKeys.language, selectedLang);
        i18n.changeLanguage(selectedLang);
    }

    return (
        <footer className="footer">
            <div className="actions">
                <div className="user-profile">
                    <User />
                    <p>{account?.Username}</p>
                </div>
                <hr style={{ height: '50%' }} />
                <div className="localization-container">
                    <p>{t('login.en')}</p>
                    <SwitchComponent checked={!isEN} change={handleChange} />
                    <p>{t('login.cn')}</p>
                </div>
            </div>
            <div className="logo">
                <FooterTableActions />
                <hr style={{ height: '50%', marginRight: '10px' }} />
                <img src="assets/find.png" alt="Powered by Find" />
            </div>
        </footer>
    );
}