import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filter: {},
  orders: [],
  selectedOrders: []
}

export const icplSlice = createSlice({
  name: 'icpl',
  initialState: initialState,
  reducers: {
    setICPLFilter: (state, action) => {
        state.filter = {
            ...state.filter,
            ...action.payload
        };
    },
    icplReset: () => {
      return initialState;
    },
    setSelectedOrders: (state, action) => {
      state.selectedOrders = action.payload;
    }
  }
})

export const { setICPLFilter, icplReset, setSelectedOrders } = icplSlice.actions;

export const icplStore = state => state.icpl;

export default icplSlice.reducer