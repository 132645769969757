import axios from "axios";
import AthenaSettings, { AthenaSettingsKeys } from "./athena_settings";

const baseURLS = {
    dev: 'http://127.0.0.1:8000/api/',
    prod: 'https://findplus.w-locate.com/integration/'
}

export const baseURL = baseURLS.prod;
export const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        const token = account.token;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default axiosInstance;