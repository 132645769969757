import { useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import Swal from 'sweetalert2';

export default function PdfViewer(props) {

    useEffect(() => {
        if (!props.visible) {
            const pdfLoader = document.querySelector('.pdf-viewer-overlay');
            pdfLoader.classList.remove('hide');
        }
        // eslint-disable-next-line
    }, [props.visible]);

    const handleDocumentLoad = () => {
        const pdfLoader = document.querySelector('.pdf-viewer-overlay');
        pdfLoader.classList.add('hide');
    }

    const handleRenderError = (error) => {
        if (props.visible) {
            let message = '';
            switch (error.name) {
                case 'InvalidPDFException':
                    message = 'The document is invalid or corrupted';
                    break;
                case 'MissingPDFException':
                    message = 'The document is missing';
                    break;
                case 'UnexpectedResponseException':
                    message = 'Unexpected server response';
                    break;
                default:
                    message = 'Cannot load the document';
                    break;
            }
            Swal.fire({
                icon: 'error',
                title: message,
                text: 'Try again'
            });
            props.close();
        }
    }

    return (
        <div className='pdf-viewer-container'>
            <div className='pdf-viewer-overlay'>
                <div className="loader-container">
                    <div className="lds-dual-ring"></div>
                </div>
            </div>
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', width: '100%', height: '100%' }}>
                {props.visible && <Viewer
                    fileUrl={props.fileUrl}
                    defaultScale={1.5}
                    onDocumentLoad={handleDocumentLoad}
                    renderError={handleRenderError} />}
            </div>
        </div>
    );
}