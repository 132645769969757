import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

export default function TableToolbar({onChange}) {
    return (
        <div className="table-toolbar">
            <TextBoxComponent
                cssClass="e-outline"
                placeholder="Search"
                floatLabelType='Auto'
                onChange={(e) => onChange(e.value)}
                width={'170px'} />
        </div>
    )
}