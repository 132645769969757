import { memo, useEffect, useRef, useState } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnDirective, ColumnsDirective, Toolbar, ExcelExport, Inject, Page } from "@syncfusion/ej2-react-grids";
import useIsMobile from "../../../utils/use_is_mobile";
// import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useTranslation } from "react-i18next";
import TableToolbar from "./TableToolbar";
import { useDispatch } from "react-redux";
import { setSelectedOrders } from "../redux/icpl_slice";
import PdfViewer from "../../../components/PdfViewer";

const ICPLTable = () => {
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();

    const [dialogConfig, setDialogConfig] = useState({
        visible: false,
        podUrl: '',
        header: ''
    })
    const gridRef = useRef();

    useEffect(() => {
        if (gridRef.current) gridRef.current.refreshColumns();
    }, [t]);

    const rowDataBound = (args) => {
        if (args.row.ariaRowIndex % 2 === 0) {
            args.row.classList.add('bg-grey');
        }

        else {

        }
    }

    // const tableActionTemplate = (_) => {
    //     return (
    //         <ButtonComponent cssClass='e-flat e-medium e-round' tooltip='Download' className="e-icons e-download" onClick={() => { }} isPrimary></ButtonComponent>
    //     );
    // }

    const signatureTemplate = (props) => {
        var src = '/sample_signature/' + props.TicketNum + '-SOG.jpg';

        return (<div className='image'>
            <span className="sign-on-glass-label" onClick={() => setDialogConfig({
                header: `Proof of Delivery Receipt - ${props.TicketNum}`,
                podUrl: props.PODurl,
                visible: true
            })}>SIGN ON GLASS</span>
            <img src={src} alt={props.TicketNum} height={100} width={100} />
        </div>);
    }

    const handleSearchOnChange = value => {
        if (gridRef.current) gridRef.current.search(value);
    }

    const handleCheckBoxChange = () => {
        if (gridRef.current) dispatch(setSelectedOrders(gridRef.current.getSelectedRecords()));
    }

    const handleDialogClose = () => {
        setDialogConfig({
            header: '',
            visible: false
        });
    }

    return (
        <>
            <TableToolbar onChange={handleSearchOnChange} />
            <GridComponent
                ref={gridRef}
                dataSource={CRPSampleData}
                rowDataBound={rowDataBound}
                enableAdaptiveUI={isMobile}
                allowTextWrap={true}
                // toolbarClick={toolbarClick}
                allowExcelExport={false}
                allowPaging={true}
                // allowFiltering={true}
                // allowSorting={true}
                allowResizing={true}
                // childGrid={childGridOptions}
                // recordClick={args => console.log(args)}
                filterSettings={{ type: 'Excel' }}
                sortSettings={{ columns: [{ field: 'Date', direction: 'Descending' }] }}
                checkBoxChange={handleCheckBoxChange}
                pageSettings={{ pageSize: 20 }}
                selectionSettings={{
                    checkboxOnly: true,
                }}>
                <ColumnsDirective>
                    <ColumnDirective type='checkbox' className='bg-blue' allowResizing={false} width="85" textAlign="left" />
                    <ColumnDirective field='LoadDate' width={120} headerText={t('icpl.columnLoadDate')} />
                    <ColumnDirective field='LoadTime' width={120} headerText={t('icpl.columnLoadTime')} />
                    <ColumnDirective field='TicketNum' width={200} headerText={t('icpl.columnTicketNo')} />
                    <ColumnDirective field='Vehicle' width={120} headerText={t('icpl.columnVehicle')} />
                    <ColumnDirective field='PlantName' width={180} headerText={t('icpl.columnPlantName')} />
                    <ColumnDirective template={signatureTemplate} width={180} headerText={t('icpl.columnProofDelivery')} />
                    <ColumnDirective field='CustomerName' width={200} headerText={t('icpl.columnCustomerName')} />
                    <ColumnDirective field='ProjectName' width={200} headerText={t('icpl.columnProjectName')} />
                    <ColumnDirective field='ProjectLocation' width={200} headerText={t('icpl.columnProjectLocation')} />
                    <ColumnDirective field='SiteDetails' width={200} headerText={t('icpl.columnSiteDetails')} />
                    <ColumnDirective field='SitePersonnel' width={150} headerText={t('icpl.columnSitePerson')} />
                    <ColumnDirective field='SiteContactNo' width={150} headerText={t('icpl.columnSiteContact')} />
                    <ColumnDirective field='Distance' width={150} headerText={t('icpl.columnDistance')} />
                    <ColumnDirective field='ArrivedSite' width={150} headerText={t('icpl.columnArrivedSite')} />
                    <ColumnDirective field='UnloadStart' width={150} headerText={t('icpl.columnUnloadStart')} />
                    <ColumnDirective field='UnloadEnd' width={150} headerText={t('icpl.columnUnloadEnd')} />
                    <ColumnDirective field='LeftSite' width={150} headerText={t('icpl.columnLeftSite')} />
                    <ColumnDirective field='Product' width={150} headerText={t('icpl.columnProduct')} />
                    <ColumnDirective field='Quantity' width={150} headerText={t('icpl.columnQuantity')} />
                    <ColumnDirective field='ProgressiveTotal' width={200} headerText={t('icpl.columnProgressive')} />
                    <ColumnDirective field='TotalOrder' width={200} headerText={t('icpl.columnTotalOrder')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnStrengthClass')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnExposureClass')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnChlorideClass')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnConsistenceClass')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnLimitValue')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnLimitValue2')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnTypeStrength')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnTypeStrength2')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnAdmixture')} />
                    <ColumnDirective field='' width={150} headerText={t('icpl.columnAdditionType')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnAggregateSize')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnAggregateSize2')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnStructuralElem')} />
                    <ColumnDirective field='CubesMade' width={150} headerText={t('icpl.columnCubesMade')} />
                    <ColumnDirective field='Slump' width={150} headerText={t('icpl.columnSlump')} />
                    <ColumnDirective field='Temperature' width={200} headerText={t('icpl.columnTemperature')} />
                    <ColumnDirective field='FieldAssistant' width={150} headerText={t('icpl.columnFieldAssitant')} />
                    <ColumnDirective field='PurchaserRemarks' width={150} headerText={t('icpl.columnRemarks')} />
                    <ColumnDirective field='' width={200} headerText={t('icpl.columnPurchaserRemarks')} />
                    <ColumnDirective field='ChemicalApplicator' width={200} headerText={t('icpl.columnChemApplicator')} />
                    <ColumnDirective field='PurchaseRepresentative' width={200} headerText={t('icpl.columnPurchaserRepresentative')} />
                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, Page]} />
            </GridComponent>
            <DialogComponent
                id="icpl-table-dialog"
                close={handleDialogClose}
                closeOnEscape={false}
                width={isMobile ? '95%' : '980px'}
                isModal={true}
                showCloseIcon={true}
                header={dialogConfig.header}
                // target='#dialog-target' 
                visible={dialogConfig.visible}
            // close={dialogClose} 
            // overlayClick={onOverlayClick}
            >
                <PdfViewer visible={dialogConfig.visible} fileUrl={`sample_pdf/${dialogConfig.podUrl}`} close={handleDialogClose} />
            </DialogComponent>
        </>
    );
}

export default memo(ICPLTable);

const CRPSampleData = [
    {
        LoadDate: '05-Sep-2023',
        LoadTime: '18:27',
        TicketNum: '11312601',
        Vehicle: '9030',
        PlantName: 'SUNGEI KADUT',
        ProofOfDelivery: 'SIGN ON GLASS',
        PODurl: '11312601.pdf',
        CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
        ProjectName: 'ZOOLOGICAL GARDENS',
        ProjectLocation: 'MANDAI LAKE RD (INSIDE MANDAI ZOO) EPS',
        SiteDetails: '',
        SitePersonnel: 'MR MARCUS 93393161',
        SiteContactNo: '',
        Distance: '9.00',
        ArrivedSite: '--',
        UnloadSite: '--',
        UnloadEnd: '--',
        LeftSite: '--',
        Product: 'G40 100-150 .',
        Quantity: '3.00',
        ProgressiveTotal: '3.00',
        TotalOrder: '3.00',
        CubesMade: '0',
        Slump: '--',
        Temperature: '--',
        FieldAssistant: '--',
        PurchaserRemarks: 'shaft 44',
        ChemicalApplicator: '--',
        PurchaseRepresentative: 'chandru'
    },
    {
        LoadDate: '05-Sep-2023',
        LoadTime: '15:15',
        TicketNum: '11312527',
        Vehicle: '9051',
        PlantName: 'SUNGEI KADUT',
        ProofOfDelivery: 'SIGN ON GLASS',
        PODurl: '11312527.pdf',
        CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
        ProjectName: 'ZOOLOGICAL GARDENS',
        ProjectLocation: 'MANDAI LAKE RD (INSIDE MANDAI ZOO) SHAFT 44',
        SiteDetails: '',
        SitePersonnel: 'MR RYAN 83627918',
        SiteContactNo: '',
        Distance: '9.00',
        ArrivedSite: '--',
        UnloadSite: '--',
        UnloadEnd: '--',
        LeftSite: '--',
        Product: 'G40 100-150 .',
        Quantity: '9.00',
        ProgressiveTotal: '9.00',
        TotalOrder: '9.00',
        CubesMade: '0',
        Slump: '--',
        Temperature: '--',
        FieldAssistant: '--',
        PurchaserRemarks: '',
        ChemicalApplicator: '--',
        PurchaseRepresentative: 'shaft44 praba'
    },
    {
        LoadDate: '05-Sep-2023',
        LoadTime: '13:09',
        TicketNum: '11312475',
        Vehicle: '9192',
        PlantName: 'SUNGEI KADUT',
        ProofOfDelivery: 'SIGN ON GLASS',
        PODurl: '11312475.pdf',
        CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
        ProjectName: 'ZOOLOGICAL GARDENS',
        ProjectLocation: 'MANDAI LAKE RD (INSIDE MANDAI ZOO)',
        SiteDetails: '',
        SitePersonnel: 'MR JAMIE 90889590',
        SiteContactNo: '',
        Distance: '9.00',
        ArrivedSite: '--',
        UnloadStart: '05-Sep-2023 13:59',
        UnloadEnd: '05-Sep-2023 13:59',
        LeftSite: '--',
        Product: 'G40 100-150 .',
        Quantity: '2.00',
        ProgressiveTotal: '2.00',
        TotalOrder: '2.00',
        CubesMade: '0',
        Slump: '--',
        Temperature: '--',
        FieldAssistant: '--',
        PurchaserRemarks: '',
        ChemicalApplicator: '--',
        PurchaseRepresentative: 'hossain md sabbir'
    },
    {
        LoadDate: '05-Sep-2023',
        LoadTime: '10:12',
        TicketNum: '14380454',
        Vehicle: '9210',
        PlantName: 'PUNGGOL TIMOR',
        ProofOfDelivery: 'SIGN ON GLASS',
        PODurl: '14380454.pdf',
        CustomerName: 'EXPAND CONSTRUCTION PTE LTD',
        ProjectName: 'BIDADARI ESTATE',
        ProjectLocation: 'MOUNT VERNON RD LP 4A (HDB PUMPING STATION)',
        SiteDetails: '',
        SitePersonnel: 'MS SIM 98582857',
        SiteContactNo: '',
        Distance: '13.00',
        ArrivedSite: '--',
        UnloadStart: '05-Sep-2023 11:17',
        UnloadEnd: '05-Sep-2023 12:10',
        LeftSite: '--',
        Product: 'G40 100-150 OPC .',
        Quantity: '4.50',
        ProgressiveTotal: '4.50',
        TotalOrder: '4.50',
        CubesMade: '0',
        Slump: '--',
        Temperature: '--',
        FieldAssistant: '--',
        PurchaserRemarks: '',
        ChemicalApplicator: '--',
        PurchaseRepresentative: 'manickam'
    },
];