import { useState, memo } from "react";
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import DropdownFiltering from "../../../utils/syncfusion/dropdown_filtering";
import { Grid, Stack } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

function ICPLFilterForm() {
    const [isFetching, setFetching] = useState(false);

    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: {
            StartTime: new Date(),
            EndTime: moment().add(1, 'hours').toDate(),
            Status: '',
            CustomerName: '',
            CustomerAccNo: '',
            OriginCode: '',
            ShipToCode: '',
        }
    });

    const onSubmit = payload => {
        if (!isFetching) {
            setFetching(true);
            console.log(payload);
            setTimeout(() => {
                setFetching(false);
            }, 1500);
        }
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <Controller
                    name="StartTime"
                    control={control}
                    render={({ field }) => (
                        <DateTimePickerComponent
                            value={field.value}
                            cssClass="e-outline"
                            className="datepicker-transparent"
                            allowEdit={true}
                            floatLabelType='Always'
                            showClearButton={true}
                            format="yyyy-MMM-dd HH:mm"
                            timeFormat="HH:mm"
                            placeholder={t('icpl.startLabel')}
                            disabled={isFetching}
                            change={(args) => {
                                field.onChange({
                                    target: { value: args.value }
                                });
                            }}
                        // value={formData.startDateTime}
                        // onChange={onChangeHandler('startDateTime')} 
                        />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Controller
                    name="EndTime"
                    control={control}
                    render={({ field }) => (
                        <DateTimePickerComponent
                            value={field.value}
                            cssClass="e-outline"
                            className="datepicker-transparent"
                            allowEdit={true}
                            floatLabelType='Always'
                            showClearButton={true}
                            format="yyyy-MMM-dd HH:mm"
                            timeFormat="HH:mm"
                            placeholder={t('icpl.endLabel')}
                            disabled={isFetching}
                            change={(args) => {
                                field.onChange({
                                    target: { value: args.value }
                                });
                            }}
                        // value={formData.startDateTime}
                        // onChange={onChangeHandler('startDateTime')} 
                        />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Controller
                    name="Status"
                    control={control}
                    render={({ field }) => (
                        <ComboBoxComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Auto'
                            dataSource={[]}
                            autofill={true}
                            // onChange={(val) => valueOnChange('Vehicle', val)}
                            showPopupButton={true}
                            disabled={isFetching}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, [], 'text')}
                            // fields={comboBoxFields}
                            placeholder={t('icpl.deliveryStatLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="CustomerName"
                    control={control}
                    render={({ field }) => (
                        <ComboBoxComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Auto'
                            dataSource={[]}
                            autofill={true}
                            disabled={isFetching}
                            // onChange={(val) => valueOnChange('Vehicle', val)}
                            showPopupButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, [], 'text')}
                            // fields={comboBoxFields}
                            placeholder={t('icpl.customerNameLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="CustomerAccNo"
                    control={control}
                    render={({ field }) => (
                        <ComboBoxComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Auto'
                            dataSource={[]}
                            autofill={true}
                            disabled={isFetching}
                            // onChange={(val) => valueOnChange('Vehicle', val)}
                            showPopupButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, [], 'text')}
                            // fields={comboBoxFields}
                            placeholder={t('icpl.customerCodeLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="OriginCode"
                    control={control}
                    render={({ field }) => (
                        <ComboBoxComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Auto'
                            dataSource={[]}
                            autofill={true}
                            disabled={isFetching}
                            // onChange={(val) => valueOnChange('Vehicle', val)}
                            showPopupButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, [], 'text')}
                            // fields={comboBoxFields}
                            placeholder={t('icpl.batchingPlantLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="ShipToCode"
                    control={control}
                    render={({ field }) => (
                        <ComboBoxComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Auto'
                            dataSource={[]}
                            autofill={true}
                            disabled={isFetching}
                            // onChange={(val) => valueOnChange('Vehicle', val)}
                            showPopupButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, [], 'text')}
                            // fields={comboBoxFields}
                            placeholder={t('icpl.projectSiteLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <div className="center-content">
                    <Stack spacing={2} direction={'row'}>
                        <ButtonComponent
                            cssClass="e-warning"
                            disabled={isFetching}
                            style={{
                                width: '130px'
                            }}>
                            {t('icpl.clearBtnTitle')}
                        </ButtonComponent>
                        <ProgressButtonComponent
                            cssClass="e-primary"
                            content={t('icpl.filterBtnTitle')}
                            duration={!isFetching ? 600 : 100000}
                            spinSettings={{
                                position: 'Center'
                            }}
                            animationSettings={{
                                effect: 'ZoomOut'
                            }}
                            style={{
                                width: '130px'
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                        </ProgressButtonComponent>
                    </Stack>
                </div>
            </Grid>
        </Grid>
    );
}

const ICPLFilter = () => {
    const [isExpanded, setIsExpanded] = useState(true);

    const animation = { expand: { effect: '', duration: 0, easing: '' }, collapse: { effect: '', duration: 0, easing: '' } };

    return (
        <AccordionComponent expanded={(event) => setIsExpanded(event.isExpanded)} animation={animation} expandMode='Single'>
            <AccordionItemsDirective>
                <AccordionItemDirective id='filter-acrdn' expanded={isExpanded} header="Filter" content={() => (
                    <div className="e-card" id="basic_card" style={{ width: '100%' }}>
                        <div className="e-card-content">
                            <ICPLFilterForm />
                        </div>
                    </div>
                )}></AccordionItemDirective>
            </AccordionItemsDirective>
        </AccordionComponent>
    );
}

export default memo(ICPLFilter);