import { useEffect } from 'react';
import './App.css';
import PackageJson from '../package.json';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AuthGuard from './components/guard/AuthGuard';
import RouteGuard from './components/guard/RouteGuard';
import LoginPage from './container/login';
import PageNotFound from './container/404';
import AthenaSettings, { AthenaSettingsKeys } from './utils/athena_settings';
import ICPLPage from './container/icpl';
import Header from './components/Header';

export const RouterRoutes = {
  login: '/login',
  icpl: '/icpl'
}

const router = createBrowserRouter([
  {
    path: RouterRoutes.login,
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <AuthGuard element={<Header />} />,
    children: [
      {
        path: RouterRoutes.icpl,
        element: <RouteGuard element={<ICPLPage />} />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound />
  }
]);

function App() {
  useEffect(() => {
    checkCache();
  }, []);

  const checkCache = () => {
    const settings = new AthenaSettings();
    if (settings.value?.version !== PackageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          });
        });

        window.location.reload(true);
      }

      settings.setItem(AthenaSettingsKeys.version, PackageJson.version);
    }
  };
  
  return (
    <RouterProvider router={router} />
  );
}

export default App;
