import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './locales/i18n';
import App from './App';
import { registerLicense } from '@syncfusion/ej2-base'
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import { Provider } from 'react-redux';

registerLicense("Mgo+DSMBPh8sVXJ0S0d+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTcEVmWH1acnRUQ2BYWA==;" +
  "ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjXH5cdHZVR2VdWU0=;" +
  "NRAiBiAaIQQuGjN/V0Z+XU9EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVlWXpfcnBXRmFZUkZ/;" +
  "NjUxMTY3QDMyMzAyZTMxMmUzMFJFY3BDRnZ3aEdreDdHd0J4aUZtbURQM1IxRDFxQm04SFcwakRQcmpyS2M9;" +
  "Mgo+DSMBMAY9C3t2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjXH5cdHZVR2VUV00=;" +
  "NjUxMTY5QDMyMzAyZTMxMmUzMGNqRDhwZHIxVW1QQm9vYVk3T0ErNS8wZ3c1azhWZzE4WXJzR2pYZjUzRFE9");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
