import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from '../container/login/redux/login_slice';
import icplReducer from '../container/icpl/redux/icpl_slice';

const appReducer = combineReducers({
    login: loginReducer,
    icpl: icplReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
