import { memo, useState } from 'react';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { setShowLoginForm } from '../redux/login_slice';
import Divider from '../../../components/Divider';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import Spacing from '../../../components/Spacing';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setSubmitting] = useState(false);

    const { t } = useTranslation();
    const { control, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            username: '',
            email_address: '',
        }
    });

    const isFieldError = value => {
        return value === undefined ? false : value !== null && value !== "";
    };

    const errorTextBoxClass = value => {
        let className = 'e-outline';
        if (isFieldError(value)) className += ' e-error'

        return className;
    }

    const errorLabelClass = value => {
        let className = 'field-error-message';
        if (!isFieldError(value)) className += ' hide'

        return className;
    }

    const onSubmit = payload => {
        setSubmitting(true);
        console.log(payload);
        setTimeout(() => {
            setSubmitting(false);
        }, 100);
    }

    return (
        <>
            <div className="flex column">
                <Spacing />
                <Controller
                    name="username"
                    control={control}
                    rules={{
                        required: t('login.invalidUsername')
                    }}
                    render={({ field }) => {
                        const errorMessage = errors.username?.message;
                        return (
                            <>
                                <TextBoxComponent
                                    value={field.value}
                                    onChange={field.onChange}
                                    cssClass={errorTextBoxClass(errorMessage)}
                                    placeholder={t('login.usernameLabel')}
                                    floatLabelType='Always' />
                                <label className={errorLabelClass(errorMessage)}>{errorMessage}</label>
                            </>
                        );
                    }} />
                <Spacing />
                <Controller
                    name="email_address"
                    control={control}
                    rules={{
                        required: t('login.emailAddressLabel')
                    }}
                    render={({ field }) => {
                        const errorMessage = errors.email_address?.message;
                        return (
                            <>
                                <TextBoxComponent
                                    value={field.value}
                                    onChange={field.onChange}
                                    cssClass={errorTextBoxClass(errorMessage)}
                                    placeholder={t('login.emailAddressLabel')}
                                    floatLabelType='Always' />
                                <label className={errorLabelClass(errorMessage)}>{errorMessage}</label>
                            </>
                        );
                    }} />
                <Spacing />
                <ProgressButtonComponent
                    cssClass="e-primary"
                    content={t('login.resetPassBtnTitle')}
                    duration={!isSubmitting ? 800 : 100000}
                    spinSettings={{
                        position: 'Center'
                    }}
                    animationSettings={{
                        effect: 'ZoomOut'
                    }}
                    onClick={handleSubmit(onSubmit)}>
                </ProgressButtonComponent>
                <Divider />
                <div className="center-content">
                    <Link className='forgot-password' onClick={() => dispatch(setShowLoginForm(true))}>{t('login.returnUserLogin')}</Link>
                </div>
            </div>
        </>
    );
}

export default memo(ForgotPassword);