import { createSlice } from '@reduxjs/toolkit';
import AthenaSettings, { AthenaSettingsKeys } from '../../../utils/athena_settings';

const initialState = {
  showLoginForm: true,
  isLoggedIn: false,
  account: {}
}

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setAccount: (state, action) => {
      const settings = new AthenaSettings();
      settings.setItem(AthenaSettingsKeys.account, action.payload);
      state.isLoggedIn = true;
      state.account = action.payload;
    },
    logoutAccount: () => {
      const settings = new AthenaSettings();
      settings.setItem(AthenaSettingsKeys.account, null);
      return initialState;
    },
    setShowLoginForm: (state, action) => {
      state.showLoginForm = action.payload;
    }
  }
})

export const { setAccount, logoutAccount, setShowLoginForm } = loginSlice.actions;

export const loginStore = state => state.login;

export default loginSlice.reducer