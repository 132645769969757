import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../../App";
import Loader from "../Loader";
import AthenaSettings, { AthenaSettingsKeys } from "../../utils/athena_settings";

export default function RouteGuard(props) {
    const navigate = useNavigate();
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        showLoader: true
    })

    useEffect(() => {
        let authInfo = { ...auth };
        const settings = new AthenaSettings();
        let account = settings.getItem(AthenaSettingsKeys.account);
        if (account) {
            authInfo.isAuthenticated = true;
            navigate(RouterRoutes.icpl);
        }

        authInfo.showLoader = false;
        setAuth(authInfo)
        // eslint-disable-next-line
    }, [navigate]);

    if (auth.showLoader) return <Loader />
    else if (auth.isAuthenticated) return props.element;
    else return <></>
}