import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import useIsMobile from "../../../utils/use_is_mobile";
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useSelector } from "react-redux";
import { icplStore } from "../../../container/icpl/redux/icpl_slice";

const items = [
    {
        text: 'Download eDOs',
    },
    {
        text: 'Download Excel',
    }
];

export default function FooterTableActions() {
    const { selectedOrders } = useSelector(icplStore);
    const { isMobile } = useIsMobile();

    const disableBtns = selectedOrders.length === 0;

    const handleDropdownSelect = e => {
        //Download eDOs
        //See items
        if (e.item.text === items[0].text) {
            handleEdosOnClick();
        }
        //Download Excel
        else if (e.item.text === items[1].text) {
            handleExcelOnClick();
        }
    }

    const handleEdosOnClick = () => {

    }

    const handleExcelOnClick = () => {

    }

    return (
        <>
            {isMobile && <DropDownButtonComponent cssClass='e-primary' select={handleDropdownSelect} items={items} disabled={disableBtns} style={{ marginRight: '10px' }}> Actions </DropDownButtonComponent>}
            {!isMobile && <div>
                <ButtonComponent cssClass='e-primary' items={items} onClick={handleEdosOnClick} disabled={disableBtns} style={{ marginRight: '10px' }}> Download eDOs </ButtonComponent>
                <ButtonComponent cssClass='e-primary' items={items} onClick={handleExcelOnClick} disabled={disableBtns} style={{ marginRight: '10px' }}> Download Excel </ButtonComponent>
            </div>}
        </>
    );
}