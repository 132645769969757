import axiosInstance from "../../../utils/axios_config"

export default function AccountService() {

    this.authenticate = async payload => {
        return axiosInstance.post('Account/Authenticate', payload);
    }

    this.getAccount = async apiKey => {
        return axiosInstance.get(`Account/Authenticate?Key=${apiKey}`);
    }
}